
















































import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import ProcessTemplateCreateModal from "../components/app/processTemplates/ProcessTemplateCreateModal.vue";
import ProcessTemplateDeleteModal from "../components/app/processTemplates/ProcessTemplateDeleteModal.vue";
import ProcessTemplateUpdateForm from "../components/app/processTemplates/ProcessTemplateUpdateForm.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import SelectBox from "../components/commons/inputsAndControls/SelectBox.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../enums/EventBus";
import {
  ExportProcessTemplateQuery, ImportProcessTemplateMutation,
  ProcessTemplate,
  ProcessTemplatesFilterEnum,
} from '../types/gqlGeneratedPrivate';
import { TableOptions, TableRefreshPayload } from "../types";
import { TablesActions, TablesNamespaces } from "../store/modules/tables";
import { eventBus } from "../utils/eventBus";
import { apiClient } from "../utils";
import { api } from "../api";

interface UpdateProcessTemplate {
  id: number;
  name: string;
  order: number;
  active: number;
  processSubCodeIds: number[];
  processSubCodes: Array<{
    id: number;
    name: string;
    code: string;
  }>;
}

@Component({
  components: {
    ActionsWrapper,
    AppLayout,
    Button,
    ProcessTemplateCreateModal,
    ProcessTemplateDeleteModal,
    ProcessTemplateUpdateForm,
    RoundButton,
    SelectBox,
    Table,
    TableHeader,
  },
})
export default class ProcessTemplatesPage extends Vue {
  editedItem: UpdateProcessTemplate | null = null;
  isFormDirty = false;
  file = null;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.ProcessTemplatesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  tableOptions: TableOptions<ProcessTemplate, ProcessTemplatesFilterEnum> = {
    defaultSortBy: ["name"],
    headers: [
      {
        text: "Název",
        sortable: true,
        align: "start",
        value: "name",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.ProcessTemplatesTable,
    key: "id",
  };

  rowClick(row: any) {
    this.updateItem(row.item, row);
  }

  deleteItem(processTemplate: ProcessTemplate): void {
    eventBus.$emit(EventBus.ProcessTemplateDeleteModal, processTemplate);
  }

  addItem(): void {
    eventBus.$emit(EventBus.ProcessTemplateCreateModal);
  }

  public async onFileChange(e: any): Promise<void> {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    console.log(await files[0].text());
    this.file = await files[0].text();
  }

  async exportCsv(): Promise<void> {
    const result = await apiClient.callGraphqlPrivate<ExportProcessTemplateQuery>({
        ...api.processTemplates.exportProcessTemplate,
      });

    const blob = new Blob([(result.data as any).exportCsv], {
      type: "text/csv",
    });
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    const now = new Date();
    elem.download = `Processes ${now.getDate()}. ${now.getMonth() + 1}. ${now.getFullYear()}.csv`;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  async importCsv(): Promise<void> {
    if (!this.file) return;
    await apiClient.callGraphqlPrivate<ImportProcessTemplateMutation>({
      ...api.processTemplates.importProcessTemplate,
      variables: {
        input: this.file,
      },
    });
    this.file = null;
    await this.refreshTable({
      namespace: TablesNamespaces.ProcessTemplatesTable,
    });
  }

  updateItem(item: UpdateProcessTemplate, row: any): void {
    row.select();
    this.editedItem = { ...item };
  }

  onItemDeleted() {
    this.editedItem = null;
    this.isFormDirty = false;
  }

  onItemUpdated() {
    this.refreshTable({
      namespace: TablesNamespaces.ProcessTemplatesTable,
    });
    this.isFormDirty = false;
  }

  onFormChanged() {
    this.isFormDirty = true;
  }
}
