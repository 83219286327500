





















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { api } from "../../../api";
import {
  ProcessSubCodeListQuery,
  ProcessSubCodeListQueryVariables,
  UpdateProcessTemplateInput,
  UpdateProcessTemplateMutation,
  UpdateProcessTemplateMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { alerts, apiClient } from "../../../utils";
import Button from "../../commons/inputsAndControls/Button.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import Autocomplete from "../../commons/inputsAndControls/Autocomplete.vue";
import { ValidationObserver } from "vee-validate";
import Textarea from "../../commons/inputsAndControls/Textarea.vue";
import SwitchField from "../../commons/inputsAndControls/SwitchField.vue";
import ProcessTemplateActivateModal from "./ProcessTemplateActivateModal.vue";
import { eventBus } from "../../../utils/eventBus";
import { EventBus } from "../../../enums";
import TextField from "../../commons/inputsAndControls/TextField.vue";

interface SubCode {
  id: number;
  name: string;
  code: string;
}

interface UpdateProcessTemplate {
  id: number;
  name: string;
  order: number;
  active: boolean;
  processSubCodeIds: number[];
  processSubCodes: SubCode[];
}

@Component({
  components: {
    TextField,
    ProcessTemplateActivateModal,
    SwitchField,
    Button,
    RoundButton,
    Textarea,
    Autocomplete,
    ValidationObserver,
  },
})
export default class ProcessTemplateUpdateForm extends Vue {
  @Prop({ required: true, type: Object })
  data!: UpdateProcessTemplate;

  private localActive = false;

  @Watch("data", { immediate: true })
  onDataChange() {
    this.editedItem = {
      ...this.data,
    };
    this.resetAutocomplete();
  }

  resetAutocomplete() {
    this.$nextTick(() => {
      // A workaround to manually upadate stale model of the autocomplete box,
      // because the :value property does not work in all situations
      // v-autocomplete inside ValidationProvider inside Autocomplete
      const autocompleteBox = (this.$refs.autocomplete as any).$children[0]
        .$children[0];
      autocompleteBox.lazyValue = null;
    });
  }

  editedItem: UpdateProcessTemplate | null = null;

  allSubItems: SubCode[] = [];

  public reorder(from: number, to: number): void {
    if (!this.editedItem) return;
    const moved = this.editedItem.processSubCodes.splice(Math.max(0, from), 1)[0];

    this.editedItem.processSubCodes.splice(to, 0, moved);
  }

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      ProcessSubCodeListQuery,
      ProcessSubCodeListQueryVariables
    >({
      ...api.processSubCodes.processSubCodeList,
      variables: {
        input: {
          pager: {
            size: 10000,
            page: 1,
          },
        },
      },
    });
    if (data?.processSubCodes) {
      this.allSubItems = data.processSubCodes.items;
    }

    this.localActive = this.editedItem?.active ?? false;
  }

  get allSubItemsForAutocomplete(): SubCode[] {
    if (this.editedItem) {
      const allowedSubItems = [];
      const subItemIdsToExclude = this.editedItem.processSubCodes;
      for (const item of this.allSubItems) {
        if (!subItemIdsToExclude.some((x) => x.id === item.id)) {
          allowedSubItems.push(item);
        }
      }
      return allowedSubItems;
    } else {
      return [];
    }
  }

  get autocompleteLabel(): string {
    if (this.allSubItemsForAutocomplete.length > 0) {
      return "Přidat";
    } else {
      return "Žádné další položky";
    }
  }

  addSubItem(subItemKey: number) {
    if (this.editedItem) {
      const subCodeIndex = this.allSubItems.findIndex(
        (item) => item.id === subItemKey
      );
      const subItem = this.allSubItems[subCodeIndex];
      if (subItem) {
        this.editedItem = {
          ...this.editedItem,
          processSubCodes: [...this.editedItem.processSubCodes, subItem],
        };
      } else {
        throw new Error(
          "Unexpected internal state of ProcessTemplateUpdateForm component."
        );
      }
    }
    this.resetAutocomplete();
  }

  deleteSubItem(toBeDeleted: SubCode) {
    if (this.editedItem) {
      const subItems = [...this.editedItem.processSubCodes];
      const index = subItems.findIndex((item) => item.id === toBeDeleted.id);
      if (index > -1) {
        subItems.splice(index, 1);
      }
      this.editedItem = {
        ...this.editedItem,
        processSubCodes: subItems,
      };
    }
    this.resetAutocomplete();
  }

  async submitUpdate() {
    if (this.editedItem) {
      const updatedItem: UpdateProcessTemplateInput = {
        name: this.editedItem.name,
        order: this.editedItem.order,
        processSubCodeIds: this.editedItem.processSubCodes.map(
          (item) => item.id
        ),
        active: this.editedItem.active,
      };

      const result = await apiClient.callGraphqlPrivate<
        UpdateProcessTemplateMutation,
        UpdateProcessTemplateMutationVariables
      >({
        ...api.processTemplates.updateProcessTemplate,
        variables: {
          id: this.editedItem.id,
          input: updatedItem,
        },
      });
      if (result.data) {
        alerts.addSuccessAlert("UPDATE_PROCESS_TEMPLATE", "Uloženo");
        this.$emit("updated");
      }
    }
  }

  activate(processTemplate: UpdateProcessTemplate | null): void {
    eventBus.$emit(EventBus.ProcessTemplateActivateModal, processTemplate);
  }

  onItemActivated(active: boolean): void {
    if (this.editedItem) {
      this.editedItem.active = active;
    }
  }

  @Watch("editedItem", { deep: true })
  onInput(a: any, b: any) {
    if (JSON.stringify(a) !== JSON.stringify(b)) {
      this.$emit("changed");
    }

    this.localActive = this.editedItem?.active ?? false;
  }
}
